<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Rider Salary Create</h1>
                    </div><!-- /.col --> 
                </div><!-- /.row --> 
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">                            
                            <div class="card-header" v-if="authUser.role_id <= 2">
                                <form v-on:keyup.enter="searchData">
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <label for="hub_id">Hub <span class="text-danger" title="Required">*</span></label>
                                                <v-select name="hub_id"
                                                    v-model="search.hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="collectionTable" class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <td colspan="5">
                                                        <label><p>Total Rider : {{ form.total_rider }} </p></label>, 
                                                        <label><p>Total Salary : {{ form.final_amount }}</p></label>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-success float-right btn-sm" @click="submitSalary"> Submit</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Rider Name</th>
                                                    <th>Rider Mobile</th>
                                                    <th>Total Order</th>
                                                    <th>Advacned Amount</th>
                                                    <th>Salary</th>
                                                    <th>Final Amount</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>Rider Name</th>
                                                    <th>Rider Mobile</th>
                                                    <th>Total Order</th>
                                                    <th>Advacned Amount</th>
                                                    <th>Salary</th>
                                                    <th>Final Amount</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index">
                                                    <td>{{ item.rider_name }} </td>
                                                    <td>{{ item.rider_mobile }} </td>
                                                    <td>{{ item.total_order }} </td>
                                                    <td>{{ item.total_advanced }}</td>
                                                    <td>{{ item.salary }}</td>
                                                    <td>{{ item.final_amount }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    export default {
        name:'MonthlySalaryCreate',
        data(){
            return {   
                loader: false,   
                listData: [],
                search: {
                    hub_id: '',
                },
                form: {
                    year: 0,
                    month: 0,
                    total_rider: 0,
                    total_salary: 0,
                    salary_advanced: 0,
                    final_amount: 0,
                    riders: [],
                    riderIds: []
                },
                errors:[],
            }
        },
        computed : {
            hubList: function () {
                return this.$store.state.commonObj.hubList
            },
            riderList: function () {
                return this.$store.state.commonObj.riderList
            },
            authUser () {
                return this.$store.state.authUser
            }
        },
        methods:{
            async submitSalary () {
                this.loader = true
                const formData = Object.assign(this.form, { hub_id: this.search.hub_id })
                const response = await config.postData('/rider-monthly-salary/store', formData)
                this.loader = false
                if (response.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: 'Salary Created',
                        color: '#218838'
                    }) 
                    this.$router.push('rider-monthly-salary')
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })
                }
            },
            searchData () {
                const isCheckHubSelected = this.checkHubSelected(this.search)
                if (isCheckHubSelected) {
                    this.loadData()
                }
            },
            async loadData(){     
                this.loader = true  
                const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search
                const response = await config.getData('/rider-monthly-salary/check-salary', params)
                this.loader = false
                if (response.success){
                    this.form.riders = this.listData = this.getRelationalData(response.data)
                } else {
                    this.form.riders = this.listData = []
                    this.form.total_rider = 0
                    this.form.total_salary = 0
                } 
            },
            getRelationalData (data) {
                this.form.total_rider = data.length
                return data.map(item => {
                    const rider = this.riderList.find(tmp => tmp.id == item.rider_id)
                    const salary = parseInt(item.remuneration_type) == 1 ? item.amount : parseFloat(item.amount * item.total_order)
                    const finalAmount = parseFloat(salary - item.total_advanced)
                    this.form.year  = parseInt(item.year)
                    this.form.month = parseInt(item.month)
                    this.form.total_salary += salary
                    this.form.salary_advanced += item.total_advanced
                    this.form.final_amount += finalAmount
                    this.form.riderIds = [...this.form.riderIds, item.rider_id]
                    const riderObj = { rider_name: rider.text, rider_mobile: rider.mobile, salary: salary, final_amount: finalAmount,
                                    remuneration_type: rider.remuneration_type == 1 ? 'Monthly Salary' : 'Commission' }
                    return Object.assign(item, riderObj)
                })
            },
            checkHubSelected (search) {
                if (typeof search.hub_id == 'string') {
                    this.$toast.success({
                        title: 'Error',
                        message: 'Please Select Hub',
                        color: '#dc3545'
                    }) 
                    return false
                }
                return true
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>